var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-browser-states",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', [_c('b-card-title', [_vm._v("Browser States")])], 1), _c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "MoreVerticalIcon",
      "size": "18"
    }
  })], 1), _c('b-card-body', [_c('b-card-text', {
    staticClass: "font-small-2"
  }, [_vm._v(" Counter August 2020 ")]), _vm._l(_vm.browserData, function (browser, index) {
    return _c('div', {
      key: browser.browserImg,
      staticClass: "browser-states"
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": browser.browserImg,
        "alt": "browser img",
        "height": "30"
      }
    }), _c('h6', {
      staticClass: "align-self-center my-auto"
    }, [_vm._v(" " + _vm._s(browser.name) + " ")])], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "font-weight-bold text-body-heading mr-1"
    }, [_vm._v(_vm._s(browser.usage))]), _c('vue-apex-charts', {
      attrs: {
        "type": "radialBar",
        "height": "30",
        "width": "30",
        "options": _vm.chartData[index].options,
        "series": _vm.chartData[index].series
      }
    })], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }