var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-app-design"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" 03 Sep, 20 ")]), _c('b-card-title', {
    staticClass: "mt-1 mb-75"
  }, [_vm._v(" App design ")]), _c('b-card-text', {
    staticClass: "font-small-2 mb-2"
  }, [_vm._v(" You can Find Only Post and Quotes Related to IOS like ipad app design, iphone app design ")]), _c('div', {
    staticClass: "design-group"
  }, [_c('h6', {
    staticClass: "section-label"
  }, [_vm._v(" Team ")]), _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" Figma ")]), _c('b-badge', {
    staticClass: "ml-25",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Wireframe ")])], 1), _c('div', {
    staticClass: "design-group"
  }, [_c('h6', {
    staticClass: "section-label"
  }, [_vm._v(" Members ")]), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      "size": "34"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "text": "PI",
      "size": "32",
      "variant": "light-danger"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-14.jpg'),
      "size": "34"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-20.jpg'),
      "size": "34"
    }
  }), _c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "text": "AL",
      "size": "32",
      "variant": "light-secondary"
    }
  })], 1), _c('div', {
    staticClass: "design-planning-wrapper"
  }, [_c('div', {
    staticClass: "design-planning"
  }, [_c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Due Date ")]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" 12 Apr, 21 ")])]), _c('div', {
    staticClass: "design-planning"
  }, [_c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Budget ")]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" $49251.91 ")])]), _c('div', {
    staticClass: "design-planning"
  }, [_c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Cost ")]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" $840.99 ")])])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v(" Join Team ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }