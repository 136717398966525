var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-employee-task",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Employee Task")]), _c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "MoreVerticalIcon",
      "size": "18"
    }
  })], 1), _c('b-card-body', _vm._l(_vm.employeeData, function (employee, index) {
    return _c('div', {
      key: employee.userFullName,
      staticClass: "employee-task d-flex justify-content-between align-items-center"
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', {
      staticClass: "mr-75"
    }, [_c('b-avatar', {
      attrs: {
        "rounded": "",
        "size": "42",
        "src": employee.avatar
      }
    })], 1), _c('b-media-body', {
      staticClass: "my-auto"
    }, [_c('h6', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(employee.userFullName) + " ")]), _c('small', [_vm._v(_vm._s(employee.designation))])])], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('small', {
      staticClass: "text-muted mr-75"
    }, [_vm._v(_vm._s(employee.duration))]), _c('vue-apex-charts', {
      attrs: {
        "type": "radialBar",
        "height": "30",
        "width": "30",
        "options": _vm.chartData[index].options,
        "series": _vm.chartData[index].series
      }
    })], 1)], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }