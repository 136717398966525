var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-transaction",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Transactions")]), _c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "MoreVerticalIcon",
      "size": "18"
    }
  })], 1), _c('b-card-body', _vm._l(_vm.transactionData, function (transaction) {
    return _c('div', {
      key: transaction.mode,
      staticClass: "transaction-item"
    }, [_c('b-media', {
      attrs: {
        "no-body": ""
      }
    }, [_c('b-media-aside', [_c('b-avatar', {
      attrs: {
        "rounded": "",
        "size": "42",
        "variant": transaction.avatarVariant
      }
    }, [_c('feather-icon', {
      attrs: {
        "size": "18",
        "icon": transaction.avatar
      }
    })], 1)], 1), _c('b-media-body', [_c('h6', {
      staticClass: "transaction-title"
    }, [_vm._v(" " + _vm._s(transaction.mode) + " ")]), _c('small', [_vm._v(_vm._s(transaction.types))])])], 1), _c('div', {
      staticClass: "font-weight-bolder",
      class: transaction.deduction ? 'text-danger' : 'text-success'
    }, [_vm._v(" " + _vm._s(transaction.payment) + " ")])], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }