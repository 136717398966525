var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "24",
      "icon": "ListIcon"
    }
  }), _c('b-card-title', {
    staticClass: "ml-25"
  }, [_vm._v(" User Timeline ")])], 1), _c('feather-icon', {
    attrs: {
      "icon": "MoreVerticalIcon",
      "size": "18"
    }
  })], 1), _c('b-card-body', [_c('app-timeline', [_c('app-timeline-item', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
  }, [_c('h6', [_vm._v("12 Invoices have been paid")]), _c('small', {
    staticClass: "timeline-item-time text-nowrap text-muted ml-1"
  }, [_vm._v("12 min ago")])]), _c('p', [_vm._v("Invoices have been paid to the company.")]), _c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/icons/json.png'),
      "height": "23",
      "alt": "data.json"
    }
  })], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h6', {
    staticClass: "media-body mb-0"
  }, [_vm._v(" data.json ")])])], 1)], 1), _c('app-timeline-item', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
  }, [_c('h6', [_vm._v("Client Meeting")]), _c('small', {
    staticClass: "timeline-item-time text-nowrap text-muted ml-1"
  }, [_vm._v("45 min ago")])]), _c('p', [_vm._v("Project meeting with john @10:15am")]), _c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-50"
  }, [_c('b-avatar', {
    attrs: {
      "src": require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      "size": "38"
    }
  })], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" John Doe (Client) ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" CEO of Infibeam ")])])], 1)], 1), _c('app-timeline-item', {
    attrs: {
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
  }, [_c('h6', [_vm._v("Create a new project for client")]), _c('small', {
    staticClass: "timeline-item-time text-nowrap text-muted ml-1"
  }, [_vm._v("2 day ago")])]), _c('p', [_vm._v("Add files to new design folder")]), _c('b-avatar-group', {
    attrs: {
      "size": "35px"
    }
  }, _vm._l(_vm.avatars, function (avatar) {
    return _c('b-avatar', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        value: 'Tooltip!',
        expression: "'Tooltip!'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      key: avatar.userImg,
      staticClass: "pull-up",
      attrs: {
        "src": avatar.userImg
      }
    });
  }), 1)], 1), _c('app-timeline-item', {
    attrs: {
      "title": "Create a new project for client",
      "subtitle": "Add files to new design folder",
      "time": "5 day ago",
      "variant": "danger"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }