var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "chat-widget",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mr-50 badge-minimal",
    attrs: {
      "size": "34",
      "src": require('@/assets/images/avatars/1.png'),
      "badge": "",
      "badge-variant": "success"
    }
  }), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Carrie Hawkins ")])], 1), _c('feather-icon', {
    attrs: {
      "icon": "MoreVerticalIcon",
      "size": "18"
    }
  })], 1), _c('section', {
    staticClass: "chat-app-window"
  }, [_c('vue-perfect-scrollbar', {
    ref: "refChatLogPS",
    staticClass: "user-chats scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('chat-log', {
    attrs: {
      "chat-data": _vm.chatData,
      "profile-user-avatar": require('@/assets/images/avatars/10.png')
    }
  })], 1), _c('b-form', {
    staticClass: "chat-app-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendMessage.apply(null, arguments);
      }
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge form-send-message mr-1"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter your message"
    },
    model: {
      value: _vm.chatInputMessage,
      callback: function callback($$v) {
        _vm.chatInputMessage = $$v;
      },
      expression: "chatInputMessage"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Send ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }