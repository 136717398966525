var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "card-profile",
    attrs: {
      "img-src": require('@/assets/images/banner/banner-12.jpg'),
      "img-alt": "Profile Cover Photo",
      "img-top": ""
    }
  }, [_c('div', {
    staticClass: "profile-image-wrapper"
  }, [_c('div', {
    staticClass: "profile-image p-0"
  }, [_c('b-avatar', {
    attrs: {
      "size": "114",
      "variant": "light",
      "src": require('@/assets/images/portrait/small/avatar-s-9.jpg')
    }
  })], 1)]), _c('h3', [_vm._v("Curtis Stone")]), _c('h6', {
    staticClass: "text-muted"
  }, [_vm._v(" Malaysia ")]), _c('b-badge', {
    staticClass: "profile-badge",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Pro Level ")]), _c('hr', {
    staticClass: "mb-2"
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h6', {
    staticClass: "text-muted font-weight-bolder"
  }, [_vm._v(" Followers ")]), _c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(" 10.3k ")])]), _c('div', [_c('h6', {
    staticClass: "text-muted font-weight-bolder"
  }, [_vm._v(" Projects ")]), _c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(" 156 ")])]), _c('div', [_c('h6', {
    staticClass: "text-muted font-weight-bolder"
  }, [_vm._v(" Rank ")]), _c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(" 23 ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }